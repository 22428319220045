import astron from "./images/astron.png";
import img1 from "./images/img1.png";
import img2 from "./images/img2.png";
import img3 from "./images/img3.png";
import img4 from "./images/img4.png";
import img5 from "./images/img5.png";
import img6 from "./images/img6.png";
import img7 from "./images/img7.png";
import img8 from "./images/img8.png";
import img9 from "./images/img9.png";
import img10 from "./images/img10.png";
import img11 from "./images/img11.png";
import img12 from "./images/img12.png";
import img13 from "./images/img13.png";
import img14 from "./images/img14.png";
import img15 from "./images/img15.png";
import img16 from "./images/img16.png";

const logos = [
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={img10} />
  </div>,
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={img2} />
  </div>,
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={img5} />
  </div>,
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={img12} />
  </div>,
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={img5} />
  </div>,
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={img7} />
  </div>,
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={img11} />
  </div>,
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={img2} />
  </div>,
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={img10} />
  </div>,
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={img11} />
  </div>,
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={img12} />
  </div>,
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={img6} />
  </div>,
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={img14} />
  </div>,
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={img7} />
  </div>,
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={img2} />
  </div>,
  <div className="bg-secondary-bg/70 p-5 m-2">
    <img src={astron} />
  </div>,
];

export default logos;
